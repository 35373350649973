<div class="gamers-container-header">
  <!-- <p-menubar [model]="items" [style]="{ display: 'flex', justifyContent: 'space-between' }"> -->
  <p-menubar [model]="items()" [styleClass]="'gamers-container-header-inner'">
    <div class="gamers-container-header-inner-actions">
      <div class="gamers-container-header-inner-actions-searchbar">
        <app-search-field></app-search-field>
      </div>
      @if (loggedUser() && currentUser()) {
        <div class="gamers-container-header-inner-actions-user">
          <div class="gamers-container-header-inner-actions-user-icons">
            <i
              class="pi pi-envelope"
              pBadge
              value="{{ messages.length }}"
              [routerLink]="['/korisnik/poruke']"></i>
            <i
              class="pi pi-bell"
              pBadge
              value="{{ notifications().length }}"
              (click)="notificationop.toggle($event)"></i>
          </div>
          <div
            class="gamers-container-header-inner-actions-user-avatar"
            (click)="op.toggle($event)">
            <img class="avatar-xs" src="{{ currentUser().avatar }}" />
            <div class="avatar-xs-badge">
              <img src="{{ cdnURL }}/assets/images/red-badge.webp" />
              <span>{{ currentUser().level }}</span>
            </div>
          </div>
        </div>
      } @else {
        <button
          pButton
          type="button"
          iconPos="right"
          class="p-button-outlined gap-3"
          (click)="showDialog()">
          <span>Prijava</span>
          <i class="pi pi-user"></i>
        </button>
      }
    </div>
  </p-menubar>
  <p-overlayPanel #op [styleClass]="'gamers-container-header-dropdown'">
    <div class="gamers-container-header-dropdown-item" [routerLink]="['/korisnik']">
      Pregled profila
    </div>
    <div class="gamers-container-header-dropdown-item" [routerLink]="['/korisnik/izmjena']">
      Izmjena profila
    </div>
    <div class="gamers-container-header-dropdown-item" [routerLink]="['/korisnik/kupovina']">
      Kupovina
    </div>
    <div class="gamers-container-header-dropdown-item" [routerLink]="['/vijesti/nova']">
      Dodaj vijest
    </div>
    <div class="gamers-container-header-dropdown-item" [routerLink]="['/esport/novi']">
      Dodaj događaj
    </div>
    <div class="gamers-container-header-dropdown-item" [routerLink]="['/slika-dana/nova']">
      Dodaj sliku dana
    </div>
    <div class="gamers-container-header-dropdown-item" [routerLink]="['/klan/novi']">
      Kreiraj klan
    </div>
    <div class="gamers-container-header-dropdown-item" (click)="logOut()">Odjava</div>
  </p-overlayPanel>
  <p-dialog
    [(visible)]="display"
    [breakpoints]="{ '960px': '75vw', '640px': '100vw' }"
    [style]="{
      border: 'none',
      borderRadius: '4px',
      backgroundColor: '#151718'
    }"
    [styleClass]="'auth'"
    [contentStyle]="{ backgroundColor: 'transparent' }"
    [draggable]="false">
    <app-login (successfulLogin)="onUserLoggedIn()"></app-login>
  </p-dialog>
  <p-overlayPanel #notificationop class="main-header-user-dropdown">
    @for (notification of notifications(); track notification) {
      <div>
        {{ notification.data?.content }}
        <i class="pi pi-times-circle" (click)="markNotificationAsRead(notification)"></i>
      </div>
    }
  </p-overlayPanel>
  <p-toast></p-toast>
</div>

import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  baseUrl = environment.apiUrl;
  endpoint = 'notifications';

  constructor(private http: HttpClient) {}

  // READ
  getAll(): Observable<Notification[]> {
    return this.http.get<Notification[]>(`${this.baseUrl}/${this.endpoint}`);
  }

  // READ NOTIFICATION
  markAsRead(objToCreate: Notification): Observable<Notification> {
    return this.http.post<Notification>(`${this.baseUrl}/${this.endpoint}/read`, objToCreate);
  }
}

import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { debounceTime, map, Observable, switchMap } from 'rxjs';
import { MeilisearchService } from '../services/meilisearch.service';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { RouterLink } from '@angular/router';
import { InputTextModule } from 'primeng/inputtext';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-search-field',
  standalone: true,
  imports: [ReactiveFormsModule, AutoCompleteModule, RouterLink, InputTextModule, AsyncPipe],
  templateUrl: './search-field.component.html',
  styleUrl: './search-field.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SearchFieldComponent {
  searchForm: FormGroup;
  searchResults$: Observable<any[]> | undefined;

  constructor(
    private meilisearchService: MeilisearchService,
    private formBuilder: FormBuilder,
  ) {
    this.searchForm = this.formBuilder.group({
      query: new FormControl(''),
    });

    this.searchResults$ = this.searchForm.get('query')?.valueChanges.pipe(
      debounceTime(300),
      switchMap((query) => this.meilisearchService.search(query)),
      map((response: any) => {
        const results = [];
        for (const indexName in response) {
          results.push({ indexName, items: response[indexName] });
        }
        return results;
      }),
    );
  }
}

<form [formGroup]="searchForm">
  <p-autoComplete
    formControlName="query"
    [group]="true"
    [suggestions]="(searchResults$ | async)!"
    field="display">
    <ng-template pTemplate="input">
      <input
        type="text"
        pInputText
        placeholder="Upiši pojam za pretragu.."
        formControlName="query" />
    </ng-template>
    <ng-template let-result pTemplate="item">
      @if (result.indexName === 'users') {
        <a [routerLink]="['/korisnik', result.username]"
          >{{ result.first_name }} {{ result.last_name }}</a
        >
      }
      @if (result.indexName === 'topics') {
        <a [routerLink]="['/forum', result.forum_category_slug, result.topic_slug, result.id]">{{
          result.title
        }}</a>
      }
      @if (result.indexName === 'posts') {
        <a [routerLink]="['/post', result.post_slug]">{{ result.content }}</a>
      }
      @if (result.indexName === 'news') {
        <a [routerLink]="['/vijesti', result.news_category_slug, result.news_slug, result.id]">{{
          result.title
        }}</a>
      }
      @if (result.indexName === 'esports') {
        <a [routerLink]="['/esport', result.esport_slug, result.id]">{{ result.title }}</a>
      }
      @if (result.indexName === 'clans') {
        <a [routerLink]="['/klan', result.clan_slug]">{{ result.name }}</a>
      }
    </ng-template>
    <ng-template let-result pTemplate="group">
      <div class="group-header">{{ result.indexName }}</div>
    </ng-template>
  </p-autoComplete>
</form>

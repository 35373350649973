<div class="gamers-container-inner-component">
  <div class="gamers-container-inner-component-wrapper">
    <div class="gamers-container-inner-component-wrapper-content">
      <img ngSrc="{{ cdnURL }}/assets/images/gamers_logo.webp" alt="logo" width="259" height="48" />
    </div>
    <div class="gamers-container-inner-component-wrapper-content">
      <p>&copy; 2024 Gamers.ba. Sva prava zadržana.</p>
      <p>
        <span routerLink="/kontakt">Kontaktirajte nas</span> -
        <span routerLink="/premium">Premium</span> - <span routerLink="/faq">FAQ</span> -
        <span routerLink="/uslovi-koristenja">Uslovi korištenja</span> -
        <span routerLink="/o-nama">O nama</span>
      </p>
    </div>
  </div>
</div>

import {
  ChangeDetectionStrategy,
  Component,
  EffectRef,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  signal,
  ViewChild,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { CurrentUser } from '../models/current-user.model';
import { MenuItem, MessageService } from 'primeng/api';
import { OverlayPanel, OverlayPanelModule } from 'primeng/overlaypanel';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../core/services/auth.service';
import { InfoMessageService } from '../services/info-message.service';
import { PusherService } from '../../core/services/pusher.service';
import { isPlatformBrowser } from '@angular/common';
import { NotificationService } from '../services/notification.service';
import { MenubarModule } from 'primeng/menubar';
import { SearchFieldComponent } from '../search-field/search-field.component';
import { BadgeModule } from 'primeng/badge';
import { DialogModule } from 'primeng/dialog';
import { LoginComponent } from '../login/login.component';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    MenubarModule,
    SearchFieldComponent,
    BadgeModule,
    OverlayPanelModule,
    DialogModule,
    LoginComponent,
    ToastModule,
    ButtonModule,
  ],
  providers: [MessageService, InfoMessageService],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HeaderComponent implements OnInit, OnDestroy {
  cdnURL: string = environment.cdnURL;
  loggedUser = signal<boolean>(false);
  currentUser = signal<CurrentUser>({} as CurrentUser);
  items = signal<MenuItem[]>([]);
  notifications = signal<Notification[]>([]);
  private notificationEffect: EffectRef | undefined;
  display: boolean = false;
  @ViewChild('op') overlayPanel!: OverlayPanel;
  messages: string[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    private readonly route: ActivatedRoute,
    private readonly authService: AuthService,
    private readonly infoMessageService: InfoMessageService,
    private readonly pusherService: PusherService,
    private readonly notificationService: NotificationService,
  ) {}

  ngOnInit(): void {
    this.checkSocialSignUp();
    this.setLoginStatus();
    this.setMenuItems();
    if (this.loggedUser()) {
      this.setCurrentUser();
      this.connectToNotification();
      this.getNotifications();
    }
  }

  setMenuItems(): void {
    const userRole: string = this.userRole();

    let menuItems: MenuItem[];
    if (userRole === 'administrator' || userRole === 'moderator' || userRole === 'news-writer') {
      menuItems = [
        { label: '', icon: 'header-logo', routerLink: [''] },
        { label: 'NASLOVNA', routerLink: [''] },
        { label: 'VIJESTI', routerLink: ['/vijesti'] },
        { label: 'FORUM', routerLink: ['/forum'] },
        { label: 'E-SPORT', routerLink: ['/esport'] },
        { label: 'ADMINISTRACIJA', routerLink: ['/administracija'] },
      ];
    } else {
      menuItems = [
        { label: '', icon: 'header-logo', routerLink: [''] },
        { label: 'NASLOVNA', routerLink: [''] },
        { label: 'VIJESTI', routerLink: ['/vijesti'] },
        { label: 'FORUM', routerLink: ['/forum'] },
        { label: 'E-SPORT', routerLink: ['/esport'] },
      ];
    }
    this.items.set(menuItems);
  }

  checkSocialSignUp() {
    const socialPlatform = this.route.snapshot.queryParamMap.get('platform');
    const socialCode = this.route.snapshot.queryParamMap.get('code');
    if (socialPlatform && socialCode) {
      this.authService.socialCallBack(socialPlatform, socialCode).subscribe((res) => {
        if (isPlatformBrowser(this.platformId)) {
          localStorage.setItem('access_token', res.access_token);
          this.currentUser.set({
            id: res.user.id,
            username: res.user.username,
            level: res.user.level_id,
            avatar: res.user.avatar,
          });
          localStorage.setItem('currentUser', JSON.stringify(this.currentUser()));
        }
        this.setLoginStatus();
        this.infoMessageService.showMessage(
          'success',
          'Prijava uspiješna!',
          `Dobrodošao ${res.user.username}!`,
        );
      });
    }
  }

  setLoginStatus() {
    this.loggedUser.set(this.authService.isLoggedIn);
  }

  setCurrentUser() {
    if (this.loggedUser()) {
      this.currentUser.set(this.authService.getCurrentUser());
    }
  }

  showDialog() {
    this.display = true;
  }

  onUserLoggedIn() {
    this.display = false;
    this.loggedUser.set(true);
    this.setCurrentUser();
    this.setMenuItems();
  }

  logOut(): void {
    this.authService.doLogout();
    this.loggedUser.set(false);
    this.currentUser.set({} as CurrentUser);
    this.infoMessageService.showMessage('info', 'Odjava uspiješna!', 'Uspiješno ste se odjavili!');
    this.display = false;
    if (this.overlayPanel) {
      this.overlayPanel.hide();
    }
    this.setMenuItems();
  }

  getNotifications() {
    this.notificationService.getAll().subscribe((notifications) => {
      this.notifications.set(notifications);
    });
  }

  markNotificationAsRead(notification: Notification) {
    this.notificationService.markAsRead(notification).subscribe(() => {
      this.getNotifications();
    });
  }

  connectToNotification() {
    const pusher = this.pusherService.getPusher();
    const channel = pusher.subscribe(`private-App.Models.User.${this.currentUser().id}`);

    channel.bind(
      'Illuminate\\Notifications\\Events\\BroadcastNotificationCreated',
      (notification: Notification) => {
        this.notifications.set([notification, ...this.notifications()]);
      },
    );
  }

  userRole(): string {
    return this.authService.userRole;
  }
  ngOnDestroy(): void {
    if (this.notificationEffect) this.notificationEffect.destroy();
  }
}

import { Routes } from '@angular/router';
import { AppLayoutComponent } from './shared/app-layout/app-layout.component';

export const routes: Routes = [
  {
    path: '',
    component: AppLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () =>
          import('./features/homepages/homepages.routes').then((m) => m.homepageRoutes),
      },
      {
        path: 'vijesti',
        loadChildren: () => import('./features/news/news.routes').then((m) => m.newsRoutes),
      },
      {
        path: 'forum',
        loadChildren: () => import('./features/forums/forums.routes').then((m) => m.forumsRoutes),
      },
      {
        path: 'korisnik',
        loadChildren: () => import('./features/users/users.routes').then((m) => m.userRoutes),
      },
      {
        path: 'esport',
        loadChildren: () => import('./features/esports/esports.routes').then((m) => m.esportRoutes),
      },
      {
        path: 'klan',
        loadChildren: () => import('./features/clans/clans.routes').then((m) => m.clanRoutes),
      },
      {
        path: 'slika-dana',
        loadChildren: () =>
          import('./features/daily-pictures/daily-pictures.routes').then(
            (m) => m.dailyPicturesRoutes,
          ),
      },
      {
        path: 'administracija',
        loadChildren: () =>
          import('./features/administrations/administrations.routes').then(
            (m) => m.administrationRoutes,
          ),
      },
      {
        path: '',
        loadChildren: () => import('./pages/pages.routes').then((m) => m.pageRoutes),
      },
      { path: '**', redirectTo: '' },
    ],
  },
];

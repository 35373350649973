<div class="auth">
  <div class="auth-wrapper">
    @if (!registerScreen) {
      <form class="auth-wrapper-form" [formGroup]="loginForm" (ngSubmit)="logIn()">
        <div class="p-inputgroup" [style]="{ height: '50px' }">
          <span
            class="p-inputgroup-addon"
            [style]="{
              borderRadius: '8px 0 0 8px',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"
            ><i class="pi pi-user"></i
          ></span>
          <input
            [style]="{
              borderRadius: '0 8px 8px 0',
              borderColor: 'black',
              backgroundColor: 'transparent'
            }"
            type="text"
            pInputText
            placeholder="Email"
            formControlName="email" />
        </div>
        @if (
          loginEmail?.invalid &&
          (loginEmail?.dirty || loginEmail?.touched) &&
          loginEmail?.errors?.['required']
        ) {
          <small class="p-error">Email je obavezan</small>
        }
        @if (
          loginEmail?.invalid &&
          (loginEmail?.dirty || loginEmail?.touched) &&
          loginEmail?.errors?.['email']
        ) {
          <small class="p-error">Email nije tačan</small>
        }
        <div class="p-inputgroup" [style]="{ height: '50px' }">
          <span
            class="p-inputgroup-addon"
            [style]="{
              borderRadius: '8px 0 0 8px',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"
            ><i class="pi pi-key"></i
          ></span>
          <input
            [style]="{
              borderColor: 'black',
              backgroundColor: 'transparent',
              borderRight: 'none'
            }"
            type="{{ isPasswordHidden ? 'password' : 'text' }}"
            placeholder="Lozinka"
            pInputText
            formControlName="password" />
          <span
            class="p-inputgroup-addon"
            pTooltip="{{ isPasswordHidden ? 'Prikaži lozinku' : 'Sakrij lozinku' }}"
            tooltipPosition="top"
            tooltipStyleClass="tooltip"
            (click)="togglePassword()"
            [style]="{
              borderRadius: '0 8px 8px 0',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"
            ><i class="pi pi-{{ isPasswordHidden ? 'eye' : 'eye-slash' }}"></i
          ></span>
        </div>
        @if (
          loginPassword?.invalid &&
          (loginPassword?.dirty || loginPassword?.touched) &&
          loginPassword?.errors?.['required']
        ) {
          <small class="p-error">Password je obavezan</small>
        }
        <div class="auth-wrapper-form-options">
          <span><p-checkbox name="groupname" value="val1" label="Zapamti me"></p-checkbox></span>
          <button
            pButton
            type="submit"
            class="p-button-outlined auth-container-content-auth-button"
            [disabled]="!loginForm.valid">
            Login
          </button>
        </div>
        <p-messages></p-messages>
        @if (resendEmail) {
          <div class="alert" (click)="resendVerificationEmail(loginForm.controls['email'].value)">
            <i class="pi pi-link"></i>
            <span>Pošalji ponovo verifikacijski email</span>
          </div>
        }
        <div class="auth-wrapper-form-options">
          <span class="auth-wrapper-form-options-text" (click)="startRegistration()"
            >Registriraj se</span
          >
          <span class="auth-wrapper-form-options-text" routerLink="/zaboravljena-lozinka"
            >Zaboravljena šifra?</span
          >
        </div>
        <p-divider align="center" [style]="{ color: 'black' }">
          <span
            [style]="{
              backgroundColor: '#151718',
              padding: '0 10px',
              color: '#3B3B3B'
            }"
            >ili</span
          >
        </p-divider>
        <button
          type="button"
          class="social-button social-button-facebook cursor-pointer"
          (click)="socialLogin('facebook')">
          <div><i class="pi pi-facebook" style="font-size: 20px"></i></div>
          <h1>Login with Facebook</h1>
        </button>
        <button
          type="button"
          class="social-button social-button-google cursor-pointer"
          (click)="socialLogin('google')">
          <div><i class="pi pi-google" style="font-size: 20px"></i></div>
          <h1>Login with Google</h1>
        </button>
      </form>
    }
    @if (registerScreen) {
      <form class="auth-wrapper-form" [formGroup]="registerForm" (ngSubmit)="register()">
        <div class="p-inputgroup" [style]="{ height: '50px' }">
          <span
            class="p-inputgroup-addon"
            [style]="{
              borderRadius: '8px 0 0 8px',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"
            ><i class="pi pi-user-edit"></i
          ></span>
          <input
            [style]="{
              borderRadius: '0 8px 8px 0',
              borderColor: 'black',
              backgroundColor: 'transparent'
            }"
            type="text"
            pInputText
            placeholder="Ime"
            formControlName="first_name" />
        </div>
        @if (
          firstName?.invalid &&
          (firstName?.dirty || firstName?.touched) &&
          firstName?.errors?.['required']
        ) {
          <small class="p-error">Ime je obavezno</small>
        }
        <!-- <p-calendar></p-calendar> -->
        <div class="p-inputgroup" [style]="{ height: '50px' }">
          <span
            class="p-inputgroup-addon"
            [style]="{
              borderRadius: '8px 0 0 8px',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"
            ><i class="pi pi-user-edit"></i
          ></span>
          <input
            [style]="{
              borderRadius: '0 8px 8px 0',
              borderColor: 'black',
              backgroundColor: 'transparent'
            }"
            type="text"
            pInputText
            placeholder="Prezime"
            formControlName="last_name" />
        </div>
        @if (
          lastName?.invalid &&
          (lastName?.dirty || lastName?.touched) &&
          lastName?.errors?.['required']
        ) {
          <small class="p-error">Prezime je obavezno</small>
        }
        <div class="p-inputgroup" [style]="{ height: '50px' }">
          <span
            class="p-inputgroup-addon"
            [style]="{
              borderRadius: '8px 0 0 8px',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"
            ><i class="pi pi-user-edit"></i
          ></span>
          <input
            [style]="{
              borderRadius: '0 8px 8px 0',
              borderColor: 'black',
              backgroundColor: 'transparent'
            }"
            type="text"
            pInputText
            placeholder="Korisničko ime"
            formControlName="username" />
        </div>
        @if (
          username?.invalid &&
          (username?.dirty || username?.touched) &&
          username?.errors?.['required']
        ) {
          <small class="p-error">Username je obavezan</small>
        }
        <div class="p-inputgroup" [style]="{ height: '50px' }">
          <span
            class="p-inputgroup-addon"
            [style]="{
              borderRadius: '8px 0 0 8px',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"
            ><i class="pi pi-envelope"></i
          ></span>
          <input
            [style]="{
              borderRadius: '0 8px 8px 0',
              borderColor: 'black',
              backgroundColor: 'transparent'
            }"
            type="email"
            pInputText
            placeholder="Email adresa"
            formControlName="email" />
        </div>
        @if (email?.invalid && (email?.dirty || email?.touched) && email?.errors?.['required']) {
          <small class="p-error">Email je obavezan</small>
        }
        <div class="p-inputgroup" [style]="{ height: '50px' }">
          <span
            class="p-inputgroup-addon"
            [style]="{
              borderRadius: '8px 0 0 8px',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"
            ><i class="pi pi-key"></i
          ></span>
          <input
            [style]="{
              borderColor: 'black',
              backgroundColor: 'transparent',
              borderRight: 'none'
            }"
            type="{{ isPasswordHidden ? 'password' : 'text' }}"
            placeholder="Lozinka"
            pInputText
            formControlName="password" />
          <span
            class="p-inputgroup-addon"
            pTooltip="{{
              isPasswordHidden && registerScreen ? 'Prikaži lozinke' : 'Sakrij lozinke'
            }}"
            tooltipPosition="top"
            tooltipStyleClass="tooltip"
            (click)="togglePassword()"
            [style]="{
              borderRadius: '0 8px 8px 0',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"
            ><i class="pi pi-{{ isPasswordHidden ? 'eye' : 'eye-slash' }}"></i
          ></span>
        </div>
        @if (
          password?.invalid &&
          (password?.dirty || password?.touched) &&
          password?.errors?.['required']
        ) {
          <small class="p-error">Password je obavezan</small>
        }
        <div class="p-inputgroup" [style]="{ height: '50px' }">
          <span
            class="p-inputgroup-addon"
            [style]="{
              borderRadius: '8px 0 0 8px',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"
            ><i class="pi pi-key"></i
          ></span>
          <input
            [style]="{
              borderColor: 'black',
              backgroundColor: 'transparent',
              borderRight: 'none'
            }"
            type="{{ isPasswordHidden ? 'password' : 'text' }}"
            placeholder="Potvrdi lozinku"
            pInputText
            formControlName="confirm_password" />
          <span
            class="p-inputgroup-addon"
            [style]="{
              borderRadius: '0 8px 8px 0',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"></span>
        </div>
        <div class="p-inputgroup" [style]="{ height: '50px' }">
          <span
            class="p-inputgroup-addon"
            [style]="{
              borderRadius: '8px 0 0 8px',
              padding: '16px',
              borderColor: 'black',
              color: '#da063e'
            }"
            ><i class="pi pi-calendar"></i
          ></span>
          <input
            [style]="{
              borderRadius: '0 8px 8px 0',
              borderColor: 'black',
              backgroundColor: 'transparent'
            }"
            type="date"
            placeholder="Datum rođenja"
            pInputText
            formControlName="birth_date" />
        </div>
        @if (
          birthDate?.invalid &&
          (birthDate?.dirty || birthDate?.touched) &&
          birthDate?.errors?.['required']
        ) {
          <small class="p-error">Datum rođenja je obavezan</small>
        }
        <div class="auth-wrapper-form-options">
          <span (click)="startRegistration()" class="auth-wrapper-form-options-text"
            >Prijavi se</span
          >
          <button
            pButton
            type="submit"
            class="p-button-outlined auth-container-content-auth-button"
            [disabled]="!registerForm.valid">
            Registriraj se
          </button>
        </div>
      </form>
    }
  </div>
</div>

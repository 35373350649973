import {
  Component,
  Output,
  EventEmitter,
  NgZone,
  ChangeDetectorRef,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../core/services/auth.service';
import { Router, RouterLink } from '@angular/router';
import { InfoMessageService } from '../services/info-message.service';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { VerifyEmailService } from '../../pages/services/verify-email.service';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { DividerModule } from 'primeng/divider';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { MessagesModule } from 'primeng/messages';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    InputTextModule,
    CheckboxModule,
    DividerModule,
    RouterLink,
    TooltipModule,
    ButtonModule,
    MessagesModule,
  ],
  providers: [MessageService, InfoMessageService],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss',
})
export class LoginComponent {
  rememberMe: boolean = false;
  resendEmail: boolean = false;
  registerScreen: boolean = false;
  isPasswordHidden: boolean = true;
  apiUrl: string = environment.apiUrl;
  loginSubmitted: boolean = false;
  registrationSubmitted: boolean = false;
  @Output() successfulLogin = new EventEmitter();

  constructor(
    private readonly authService: AuthService,
    private readonly verifyEmailService: VerifyEmailService,
    private readonly ngZone: NgZone,
    private cdr: ChangeDetectorRef,
    private readonly infoMessageService: InfoMessageService,
    public router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {}

  loginForm = new UntypedFormGroup({
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', Validators.required),
  });

  registerForm = new UntypedFormGroup({
    avatar: new UntypedFormControl(`${environment.cdnURL}/img/users/avatars/default.webp`),
    first_name: new UntypedFormControl('', Validators.required),
    last_name: new UntypedFormControl('', Validators.required),
    username: new UntypedFormControl('', Validators.required),
    email: new UntypedFormControl('', [Validators.required, Validators.email]),
    password: new UntypedFormControl('', Validators.required),
    confirm_password: new UntypedFormControl('', Validators.required),
    birth_date: new UntypedFormControl('', Validators.required),
  });

  logIn() {
    return this.authService.signIn(this.loginForm.value).subscribe(
      (res: any) => {
        if (!res.message) {
          this.loginSubmitted = true;
          this.loginForm.reset();
          if (isPlatformBrowser(this.platformId)) {
            localStorage.setItem('access_token', res.access_token);
            localStorage.setItem(
              'currentUser',
              JSON.stringify({
                id: res.user.id,
                username: res.user.username,
                level: res.user.level_id,
                avatar: res.user.avatar,
              }),
            );
          }
          this.infoMessageService.showMessage(
            'success',
            'Prijava uspiješna!',
            `Dobrodošao ${res.user.username}!`,
          );
          setTimeout(() => {
            this.successfulLogin.emit();
          }, 2000);
        } else {
          this.infoMessageService.showMessage(
            'error',
            'Prijava neuspiješna!',
            `Email adresa ili password nisu tačni`,
          );
        }
      },
      (error) => {
        if (error.error.message === 'User is banned') {
          this.infoMessageService.showMessage(
            'error',
            'Korisnik banovan!',
            `Banovani korisnici se ne mogu prijaviti`,
          );
        } else {
          this.resendEmail = true;
          this.infoMessageService.showMessage(
            'error',
            'Prijava neuspiješna!',
            `Email adresa nije verifikovana`,
          );
        }
      },
    );
  }

  register() {
    this.registrationSubmitted = true;
    return this.authService.signUp(this.registerForm.value).subscribe(
      () => {
        this.ngZone.run(() => {
          this.registerForm.reset();
          this.infoMessageService.showMessage(
            'success',
            'Registracija uspiješna!',
            'Provjerite email kako bi kompletirali registraciju!',
          );
          setTimeout(() => {
            this.startRegistration();
            this.cdr.markForCheck();
          }, 2000);
        });
      },
      (error) => {
        let errorMsg = '';
        if (error.error.errors.email) {
          errorMsg = 'Račun sa unesenom e-mail adresom je već kreiran.';
        }
        if (error.error.errors.username) {
          errorMsg = 'Račun sa unesenim username je već kreiran.';
        }
        if (error.error.errors.username && error.error.errors.email) {
          errorMsg = 'Račun sa unesenim username i e-mail je već kreiran.';
        }

        this.infoMessageService.showMessage('error', 'Registracija neuspiješna!', errorMsg);
      },
    );
  }
  togglePassword() {
    this.isPasswordHidden = !this.isPasswordHidden;
  }

  startRegistration() {
    this.registerScreen = !this.registerScreen;
  }

  socialLogin(provider: string) {
    this.authService.socialSignIn(provider).subscribe((data) => {
      if (typeof window !== 'undefined') {
        window.location.href = data.toString();
      }
    });
  }

  resendVerificationEmail(email: string) {
    this.verifyEmailService.verifyResend(email).subscribe(
      () => {
        this.resendEmail = false;
        this.infoMessageService.showMessage(
          'success',
          'Email poslan!',
          'Verifikacijski email poslan.',
        );
      },
      (error) => {
        this.infoMessageService.showMessage('error', 'Greška!', error.error.message);
      },
    );
  }

  get loginEmail() {
    return this.loginForm.get('email');
  }

  get loginPassword() {
    return this.loginForm.get('password');
  }

  get firstName() {
    return this.registerForm.get('first_name');
  }
  get lastName() {
    return this.registerForm.get('last_name');
  }
  get username() {
    return this.registerForm.get('username');
  }
  get email() {
    return this.registerForm.get('email');
  }
  get password() {
    return this.registerForm.get('password');
  }
  get confirmPassword() {
    return this.registerForm.get('confirm_password');
  }
  get birthDate() {
    return this.registerForm.get('birth_date');
  }
}
